import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import worksData from "../data/works";
import Work from "../components/Work";
import PortfolioSectionImage from '../assets/images/portfolio-draw.png';
import mixitup from 'mixitup';
import { Link } from "react-router-dom";

// Import FontAwesomeIcon and required icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faFileAlt, faCode, faMusic } from '@fortawesome/free-solid-svg-icons';

const Portfolio = ({ setActivePage }) => {

    const { t } = useTranslation();

    useEffect(() => {

        setActivePage('portfolio');

    }, []);

    useEffect(() => {
        // Initialize MixItUp when the component mounts
        const mixer = mixitup('.container');

        // Clean up MixItUp when the component unmounts
        return () => {
            mixer.destroy();
        };
    }, []);

    return (

        <section id="portfolio">

            <div className="section-container">

                {/* TABS START */}
                <div className="tabs is-centered" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
                    <ul>
                        <li data-filter="*" className="tab filter-btn">
                            <a>
                                <span className="icon is-small">
                                    <FontAwesomeIcon icon={faImage} aria-hidden="true" />
                                </span>
                                <span>{t(`portfolio.tabs.all`)}</span>
                            </a>
                        </li>
                        <li data-filter=".website" className="tab filter-btn">
                            <a>
                                <span className="icon is-small">
                                    <FontAwesomeIcon icon={faFileAlt} aria-hidden="true" />
                                </span>
                                <span>{t(`portfolio.tabs.websites`)}</span>
                            </a>
                        </li>
                        <li data-filter=".app" className="tab filter-btn">
                            <a>
                                <span className="icon is-small">
                                    <FontAwesomeIcon icon={faCode} aria-hidden="true" />
                                </span>
                                <span>{t(`portfolio.tabs.apps`)}</span>
                            </a>
                        </li>
                        <li data-filter=".design" className="tab filter-btn">
                            <a>
                                <span className="icon is-small">
                                    <FontAwesomeIcon icon={faMusic} aria-hidden="true" />
                                </span>
                                <span>{t(`portfolio.tabs.design`)}</span>
                            </a>
                        </li>
                        <li data-filter=".backend" className="tab filter-btn">
                            <Link to={`https://github.com/achraflafkiri`} target="_blank">
                                <span className="icon is-small">
                                    <FontAwesomeIcon icon={faCode} aria-hidden="true" />
                                </span>
                                <span>{t(`portfolio.tabs.backend`)}</span>
                            </Link>
                        </li>
                    </ul>
                </div>
                {/* TABS END */}

                <div className="portfolio-items container">
                    <div className="columns is-multiline">
                        {worksData.map((work, index) => (
                            <Work
                                key={index}
                                workImage={work.poster}
                                workLink={work.previewLink}
                                workTitle={work.title}
                                Types={work.type}
                            />
                        ))}
                    </div>
                </div>

                <div className="section-title portfolio-title">
                    <h2 className="is-uppercase title" data-localize="portfolio.heading">
                        {t('portfolio.heading')}
                    </h2>
                </div>

                <figure className="portfolio-draw-image image">
                    <img src={PortfolioSectionImage} alt="reviews about Achraf Lafkiri Services" />
                </figure>

            </div>
        </section>

    );

}

export default Portfolio;
