import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import services from "../data/services";
import { useTranslation } from 'react-i18next';

const Services = () => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;  // Get the current language ('en', 'fr', 'ar')

    return (
        <section id="services" className='services'>

            <div className="section-container">
                {/* <p className="skills-p">{t('services.section-descrip')}</p> */}

                <div className="columns is-multiline">  {/* Added is-multiline to wrap columns to new rows */}
                    {
                        services.map(service => (
                            <div className="column is-one-third" key={service.id}>  {/* Each column takes 1/3 of the row */}
                                <Link to="#">
                                    <div className="card has-text-centered">
                                        <div className="icon-wrapper">
                                            <FontAwesomeIcon icon={service.icon} />
                                        </div>
                                        <h3>{service.name[currentLanguage]}</h3>
                                        <p>{service.description[currentLanguage]}</p>
                                    </div>
                                </Link>
                            </div>
                        ))
                    }
                </div>
            </div>

        </section>
    );
}

export default Services;
