import React from 'react';
import { useTranslation } from 'react-i18next';
import packages from "../data/Packages";  // Import the packages data

const PricingPage = () => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;  // Get the current language ('en', 'fr', 'ar')

    return (
        <section id="pricing" className="section">
            <div className="section-container">
                <h2 className="title has-text-centered">
                    {currentLanguage === 'en' ? 'Pricing Packages' : currentLanguage === 'fr' ? 'Forfaits' : 'باقات الأسعار'}
                </h2>
                <div className="columns is-multiline">
                    {
                        packages.map(pkg => (
                            <div className="column is-one-third" key={pkg.id}>
                                <div className="card">
                                    <div className="card-content">
                                        <div className="content">
                                            <h3 className="title is-4">{pkg.name[currentLanguage]}</h3>
                                            <p>{pkg.description[currentLanguage]}</p>
                                            <h4 className="subtitle is-5">{pkg.price[currentLanguage]}</h4>
                                            <ul>
                                                {
                                                    pkg.features[currentLanguage].map((feature, index) => (
                                                        <li key={index} className="has-text-left">
                                                            <span className="icon-text">
                                                                <span className="icon">
                                                                    <i className="fas fa-check"></i>
                                                                </span>
                                                                <span>{feature}</span>
                                                            </span>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <footer className="card-footer">
                                        <a href="#" className="card-footer-item has-background-main has-text-white">Select</a>
                                    </footer>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    );
}

export default PricingPage;
