import { Link } from "react-router-dom"

const Ceraficate = ({ certImage, link }) => {

    return (

        <div className="column is-6">
            <Link to={link} target={link ? "_blank" : "_self"} >
                <figure className="image">
                    <img src={certImage} alt={"achraf lafkiri certifactes"} />
                </figure>
            </Link>
        </div>

    )
}

export default Ceraficate