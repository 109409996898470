import defaultPicture from "../assets/images/reviewers/person.png";
import Livrai_Liya from "../assets/images/reviewers/livrai_liya.png";
import tapestry from "../assets/images/reviewers/tapestry.png";

const reviewsData = [
  {
    reviewer: "Jacky Lee",
    logo: defaultPicture,
    texten:
      "Achraf is responsive and helpful, so i could get it setup online properly. Highly recommend to use his product.",
    textfr:
      "Achraf is responsive and helpful, so i could get it setup online properly. Highly recommend to use his product.",
    textar:
      "Achraf is responsive and helpful, so i could get it setup online properly. Highly recommend to use his product.",
  },
  {
    reviewer: "Ayoub tidili",
    logo: defaultPicture,
    texten:
      "I'm extremely pleased with Achraf's work. He completed all our requests promptly and efficiently. I highly recommend him for his excellent work.",
    textfr:
      "Je suis extrêmement satisfait du travail d'Achraf. Il a réalisé toutes nos demandes de manière rapide et efficace. Je le recommande vivement pour son excellent travail.",
    textar:
      "أنا سعيد للغاية بعمل أشرف. أنجز جميع طلباتنا بسرعة وكفاءة. أنصح بشدة به لأنه قدم عملاً ممتازًا",
  },
  {
    reviewer: "Chafik Tamer",
    logo: Livrai_Liya,
    texten:
      "Great design, I was honored to work with you, buddy. Good job, keep it up.",
    textfr:
      "Design magnifique, j'ai été honoré de travailler avec toi mon pote, bon travail, continue.",
    textar: "تصميم رائع، تشرفت بالخدمة معك خويا عمل جيد استمر",
  },
  {
    reviewer: "Azami Omar",
    logo: tapestry,
    texten:
      "I ordered Achraf to build a tapestry website, and the design was excellent. I am 100% satisfied with his work. I highly recommend him to others.",
    textfr:
      "J'ai demandé à Achraf de créer un site Web sur la tapisserie et le design était excellent. Je suis 100% satisfait de son travail. Je le recommande vivement aux autres.",
    textar: "طلبت من أشرف أن يبني موقعًا إلكترونيًا للنسيج، وكان التصميم ممتازًا. أنا راضٍ بنسبة 100% عن عمله. أنا أوصي به بشدة للآخرين.",
  },
];

export default reviewsData;
