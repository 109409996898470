import React from 'react';
import 'bulma/css/bulma.min.css'; // Make sure Bulma is imported

const Blog = () => {
    const blogs = [
        {
            id: 1,
            title: 'First Blog Post',
            description: 'This is a short description of the first blog post.',
            imageUrl: 'https://via.placeholder.com/150',
            link: '/blog/1',
        },
        {
            id: 2,
            title: 'Second Blog Post',
            description: 'This is a short description of the second blog post.',
            imageUrl: 'https://via.placeholder.com/150',
            link: '/blog/2',
        },
        {
            id: 3,
            title: 'Third Blog Post',
            description: 'This is a short description of the third blog post.',
            imageUrl: 'https://via.placeholder.com/150',
            link: '/blog/3',
        },
    ];

    return (
        <section id="blogs" className="section">
            <div className="container">
                <h2 className="title is-3">Latest Blogs</h2>
                <div className="columns is-multiline">
                    {blogs.map(blog => (
                        <div className="column is-one-third" key={blog.id}>
                            <div className="card">
                                <div className="card-image">
                                    <figure className="image is-4by3">
                                        <img src={blog.imageUrl} alt={blog.title} />
                                    </figure>
                                </div>
                                <div className="card-content">
                                    <div className="media">
                                        <div className="media-content">
                                            <p className="title is-4">{blog.title}</p>
                                        </div>
                                    </div>
                                    <div className="content">
                                        {blog.description}
                                        <br />
                                        <a href={blog.link}>Read More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Blog;
