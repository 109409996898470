import HTMLIcon from "../assets/images/skills/html.png";
import CSSIcon from "../assets/images/skills/css.png";
import JSIcon from "../assets/images/skills/js.png";
import SASSIcon from "../assets/images/skills/sass.png";
import JQUERYIcon from "../assets/images/skills/jquery.png";
import REACTIcon from "../assets/images/skills/react.png";
import PHPIcon from "../assets/images/skills/php.png";
import MYSQLIcon from "../assets/images/skills/mysql.png";
import GITIcon from "../assets/images/skills/git.png";
import GITHUBIcon from "../assets/images/skills/github.png";
import NPMIcon from "../assets/images/skills/npm.png";
import NODEIcon from "../assets/images/skills/node.png";
import LARAVELIcon from "../assets/images/skills/laravel.png";
import VueIcon from "../assets/images/skills/vue.png";
import XdIcon from "../assets/images/skills/xd.png";
import WordpressIcon from "../assets/images/skills/wordpress.png";
import GulpIcon from "../assets/images/skills/gulp.png";

const skillsData = [

    { title: 'HTML', icon: HTMLIcon },
    { title: 'CSS', icon: CSSIcon },
    { title: 'JavaScript', icon: JSIcon },
    { title: 'Sass', icon: SASSIcon },
    { title: 'Jquery', icon: JQUERYIcon },
    { title: 'React', icon: REACTIcon },
    { title: 'Php', icon: PHPIcon },
    { title: 'MySQL', icon: MYSQLIcon },
    { title: 'Git', icon: GITIcon },
    { title: 'Github', icon: GITHUBIcon },
    { title: 'Npm', icon: NPMIcon },
    { title: 'Node', icon: NODEIcon },
    { title: 'Laravel', icon: LARAVELIcon },
    { title: 'Vue', icon: VueIcon },
    { title: 'xd', icon: XdIcon },
    { title: 'Wordpress', icon: WordpressIcon },
    { title: 'Gulp', icon: GulpIcon },

];

export default skillsData;