import React, { useEffect, useState } from "react";
import waICON from "../assets/images/whatsapp.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const WhatsappICON = ({ media }) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);

  const [link, setLink] = useState();
  useEffect(() => {
    setIsActive(true);

    setLink(media[1].link);
  }, []);

  return (
    <div id="wa" className="position-relative">
      <Link to={link} target="_blank">
        <figure className={`wa-icon ${isActive ? "active" : ""}`}>
          <img src={waICON} alt={"Sofa"} />
        </figure>
        <p
          className={`wa-txt ${
            isActive ? "active" : ""
          } has-text-weight-medium has-text-dark`}
        >
          {t(`whatsapp`)}
        </p>
      </Link>
    </div>
  );
};

export default WhatsappICON;
