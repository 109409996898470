import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import transEn from "../locale/lang-en.json";
import transAr from "../locale/lang-ar.json";
import transFr from "../locale/lang-fr.json";
import transDe from "../locale/lang-de.json";
import transIt from "../locale/lang-it.json";
import transKo from "../locale/lang-ko.json";

const resources = {
  en: {
    translation: transEn
  },
  ar: {
    translation: transAr
  },
  fr: {
    translation: transFr
  },
  de: {
    translation: transDe
  },
  es: {
    translation: transDe
  },
  it: {
    translation: transIt
  },
  ko: {
    translation: transKo
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",

    interpolation: {
      escapeValue: false 
    }
  });

  export default i18n;