import { faLayerGroup, faPenNib, faWrench, faDatabase, faMobileAlt, faCode, faSearch } from "@fortawesome/free-solid-svg-icons";

const services = [
    {
        id: 1,
        name: {
            en: "Web Development Templates",
            fr: "Modèles de développement Web",
            ar: "نماذج تطوير الويب"
        },
        description: {
            en: "Create high-quality, responsive, and dynamic website templates using modern technologies such as React, Next.js, and HTML/CSS.",
            fr: "Créez des modèles de sites Web de haute qualité, réactifs et dynamiques en utilisant des technologies modernes telles que React, Next.js et HTML/CSS.",
            ar: "إنشاء قوالب مواقع ويب عالية الجودة ومتجاوبة وديناميكية باستخدام تقنيات حديثة مثل React وNext.js وHTML/CSS."
        },
        icon: faLayerGroup
    },
    {
        id: 2,
        name: {
            en: "E-commerce Solutions",
            fr: "Solutions E-commerce",
            ar: "حلول التجارة الإلكترونية"
        },
        description: {
            en: "Build complete e-commerce platforms with features like payment gateways, product management, and secure checkout using the MERN stack or Laravel.",
            fr: "Construisez des plateformes e-commerce complètes avec des fonctionnalités telles que les passerelles de paiement, la gestion des produits et un paiement sécurisé en utilisant MERN ou Laravel.",
            ar: "بناء منصات التجارة الإلكترونية الكاملة مع ميزات مثل بوابات الدفع وإدارة المنتجات والدفع الآمن باستخدام MERN أو Laravel."
        },
        icon: faPenNib
    },
    {
        id: 3,
        name: {
            en: "UI/UX Design",
            fr: "Conception UI/UX",
            ar: "تصميم واجهة المستخدم وتجربة المستخدم"
        },
        description: {
            en: "Design intuitive and visually appealing user interfaces with a focus on user experience, using Figma, Adobe XD, and implementing designs with HTML/CSS.",
            fr: "Concevez des interfaces utilisateur intuitives et visuellement attrayantes en vous concentrant sur l'expérience utilisateur, en utilisant Figma, Adobe XD et en implémentant des conceptions avec HTML/CSS.",
            ar: "تصميم واجهات مستخدم جذابة وبديهية مع التركيز على تجربة المستخدم باستخدام Figma و Adobe XD وتطبيق التصاميم باستخدام HTML/CSS."
        },
        icon: faWrench
    },
    {
        id: 4,
        name: {
            en: "Database Management",
            fr: "Gestion de base de données",
            ar: "إدارة قواعد البيانات"
        },
        description: {
            en: "Develop and manage efficient databases using MySQL, MongoDB, and PostgreSQL, ensuring optimized queries and data integrity.",
            fr: "Développez et gérez des bases de données efficaces en utilisant MySQL, MongoDB et PostgreSQL, en garantissant des requêtes optimisées et l'intégrité des données.",
            ar: "تطوير وإدارة قواعد بيانات فعالة باستخدام MySQL و MongoDB و PostgreSQL مع ضمان تحسين الاستعلامات وسلامة البيانات."
        },
        icon: faDatabase
    },
    {
        id: 5,
        name: {
            en: "Mobile App Development",
            fr: "Développement d'applications mobiles",
            ar: "تطوير تطبيقات الهاتف المحمول"
        },
        description: {
            en: "Create cross-platform mobile applications using React Native, providing seamless experiences on both iOS and Android devices.",
            fr: "Créez des applications mobiles multiplateformes en utilisant React Native, offrant une expérience fluide sur les appareils iOS et Android.",
            ar: "إنشاء تطبيقات للهاتف المحمول عبر منصات متعددة باستخدام React Native لتوفير تجربة سلسة على أجهزة iOS و Android."
        },
        icon: faMobileAlt
    },
    {
        id: 6,
        name: {
            en: "API Development & Integration",
            fr: "Développement et intégration d'API",
            ar: "تطوير ودمج واجهات برمجة التطبيقات"
        },
        description: {
            en: "Build robust RESTful APIs for web and mobile applications, integrating third-party services and ensuring secure, scalable solutions.",
            fr: "Créez des API RESTful robustes pour les applications web et mobiles, en intégrant des services tiers et en garantissant des solutions sécurisées et évolutives.",
            ar: "إنشاء واجهات برمجة تطبيقات RESTful قوية لتطبيقات الويب والهاتف المحمول مع دمج الخدمات الخارجية وضمان حلول آمنة وقابلة للتطوير."
        },
        icon: faCode
    },
    {
        id: 7,
        name: {
            en: "SEO Optimization",
            fr: "Optimisation SEO",
            ar: "تحسين محركات البحث"
        },
        description: {
            en: "Enhance website visibility with on-page and off-page SEO strategies, keyword optimization, and performance improvements.",
            fr: "Améliorez la visibilité du site Web avec des stratégies SEO on-page et off-page, une optimisation des mots-clés et des améliorations de performance.",
            ar: "تعزيز رؤية الموقع من خلال استراتيجيات تحسين محركات البحث الداخلية والخارجية وتحسين الكلمات الرئيسية وتحسين الأداء."
        },
        icon: faSearch
    }
];

export default services;
