
const FormTextarea = ({ name, label, handleOnChange, isRequired, message, setIsValidMsg }) => {
    return (
        <div className="field">
            <div className="control">
                <label htmlFor={name} className="label is-uppercase mb-3">{label}</label>
                <textarea
                    id={name}
                    className={`textarea`}
                    name={name}
                    onChange={handleOnChange}
                    value={message}
                    required={isRequired}
                ></textarea>
            </div>
        </div >
    );
}

export default FormTextarea;
