const packages = [
    {
        id: 1,
        name: {
            en: "Basic Package",
            fr: "Forfait Basique",
            ar: "الباقة الأساسية"
        },
        description: {
            en: "Perfect for startups and small projects. Includes basic website or application development with essential features and responsive design.",
            fr: "Parfait pour les startups et les petits projets. Comprend le développement de base du site Web ou de l'application avec des fonctionnalités essentielles et un design réactif.",
            ar: "مثالي للشركات الناشئة والمشاريع الصغيرة. يشمل تطوير موقع ويب أو تطبيق أساسي مع الميزات الأساسية وتصميم متجاوب."
        },
        price: {
            en: "$500",
            fr: "500€",
            ar: "500 دولار"
        },
        features: {
            en: [
                "Responsive Web Design",
                "Up to 5 Pages",
                "Basic SEO Setup",
                "1 Month Support",
            ],
            fr: [
                "Conception Web Réactive",
                "Jusqu'à 5 Pages",
                "Configuration SEO de Base",
                "1 Mois de Support",
            ],
            ar: [
                "تصميم ويب متجاوب",
                "حتى 5 صفحات",
                "إعداد SEO الأساسي",
                "دعم لمدة شهر",
            ]
        }
    },
    {
        id: 2,
        name: {
            en: "Advanced Package",
            fr: "Forfait Avancé",
            ar: "الباقة المتقدمة"
        },
        description: {
            en: "Ideal for growing businesses. Includes advanced features such as e-commerce integration, database setup, and custom API development.",
            fr: "Idéal pour les entreprises en pleine croissance. Comprend des fonctionnalités avancées telles que l'intégration e-commerce, la configuration de base de données et le développement d'API personnalisée.",
            ar: "مثالي للشركات النامية. يشمل ميزات متقدمة مثل دمج التجارة الإلكترونية، إعداد قواعد البيانات، وتطوير API مخصص."
        },
        price: {
            en: "$1,200",
            fr: "1200€",
            ar: "1200 دولار"
        },
        features: {
            en: [
                "E-commerce Integration",
                "Custom API Development",
                "Up to 10 Pages",
                "3 Months Support",
            ],
            fr: [
                "Intégration E-commerce",
                "Développement d'API personnalisée",
                "Jusqu'à 10 Pages",
                "3 Mois de Support",
            ],
            ar: [
                "دمج التجارة الإلكترونية",
                "تطوير API مخصص",
                "حتى 10 صفحات",
                "دعم لمدة 3 أشهر",
            ]
        }
    },
    {
        id: 3,
        name: {
            en: "Pro Package",
            fr: "Forfait Pro",
            ar: "الباقة الاحترافية"
        },
        description: {
            en: "For large-scale projects and enterprises. Includes full-stack web and mobile app development, advanced security, and database management.",
            fr: "Pour les projets à grande échelle et les entreprises. Comprend le développement complet d'applications web et mobiles, une sécurité avancée et la gestion des bases de données.",
            ar: "للمشاريع الكبيرة والشركات. يشمل تطوير تطبيقات ويب وجوال كاملة، أمان متقدم، وإدارة قواعد البيانات."
        },
        price: {
            en: "$2,500",
            fr: "2500€",
            ar: "2500 دولار"
        },
        features: {
            en: [
                "Full-Stack Development (Web & Mobile)",
                "Advanced Security Features",
                "Unlimited Pages",
                "6 Months Support",
            ],
            fr: [
                "Développement Full-Stack (Web & Mobile)",
                "Fonctionnalités de Sécurité Avancées",
                "Pages Illimitées",
                "6 Mois de Support",
            ],
            ar: [
                "تطوير Full-Stack (ويب وجوال)",
                "ميزات أمان متقدمة",
                "صفحات غير محدودة",
                "دعم لمدة 6 أشهر",
            ]
        }
    }
];

export default packages;
